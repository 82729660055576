import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { Row, Col } from "react-bootstrap";
import { client as prismicClient } from "../libs/prismic";
import Loader from "../components/Loader";
import { RichText } from "prismic-reactjs";

const FullDisclosure = () => {
  const [fullDisclosureDoc, setFullDisclosureDoc] = useState();
  const [content, setContent] = useState();

  useEffect(()=>{
    let content = fullDisclosureDoc?.data;
    setContent(content);

    if(!content) {
      return
    }

    content.banner_section.forEach(line => {
      if(line.text === '' && line.type === 'paragraph') {
        line.text = '\n';
      }
    })
    setContent(content);
  },[fullDisclosureDoc])

  useEffect(() => {
    async function fetchData() {
      const response = await prismicClient().getSingle("full_disclosure");
      setFullDisclosureDoc(response);
    }
    fetchData();
  }, []);

  return (
    fullDisclosureDoc ? <Layout>
      <Row className="pt-80 pb-top-40 mw-1440 mx-auto justify-content-center padding-mobile mb-140">
        <Col md={12}>
          <div className="rich-text">
            <RichText render={content?.banner_section} />
          </div>
        </Col>
      </Row>
    </Layout> : <Loader />
  );
};

export default FullDisclosure;
